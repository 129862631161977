import { render } from "solid-js/web";
import { Router } from "@solidjs/router";
import App from "./App";


import "../index.scss";
import { AuthorizationProvider } from "./context/Auth/AuthorizationProvider";

render(
  () => (
      <AuthorizationProvider>
            <Router root={App}>

            </Router>
      </AuthorizationProvider>
  ),
  document.getElementById("root")
);