const bufToHexString = (raw_array) => {
	const array = Array.from(raw_array);
	const hex = array.map(v => v.toString(16).padStart(2, '0')).join('');
    return hex;
};

const createRandomBuf = (size) => {
    const raw_array = new Uint8Array(size);
	return crypto.getRandomValues(raw_array);
};

const hashBufAsStr = async (buf) => {
    const hashBuffer = await crypto.subtle.digest('SHA-256', buf);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
	//console.log(hashHex);
    return hashHex;
};

const createState = () => {
    return window.btoa(createRandomBuf(16));
}

const createCodes = async () =>{
    const randomBuf = createRandomBuf(48);
    return [bufToHexString(randomBuf), await hashBufAsStr(randomBuf)];
}

export {
    createRandomBuf,
    createState,
    createCodes,
    bufToHexString,
    hashBufAsStr
};