import axios from "axios";
import { createState } from "./functions/customcrypto";
const useAxiosState = (baseUrl) => {
    const state = (new URLSearchParams(document.location.search)).get("state") ?? createState();

    return {
        runStatelessPost : async (endpoint, body) => {
           
            const response = await axios.post(`${baseUrl}/${endpoint}`, 
            {
                ...body
            });
            return response.data;
        },
        runPost : async (endpoint, body) => {
           
            const response = await axios.post(`${baseUrl}/${endpoint}`, 
            {
                ...body,
                state
            });
            return response.data;
        },
        runFormPost : async (formRef) =>{
            formRef.querySelector("input[name='state']").value = state;
            formRef.submit();
        },
        runPopup : async (queries, endpoint) => {
            let requestUrl = `${baseUrl}/${endpoint}?state=${encodeURIComponent(state)}&`;
            for(const query in queries){
                requestUrl += `${query}=${encodeURIComponent(queries[query])}&`
            }
            window.open(requestUrl.slice(0,-1));
        },
        runIFrame : async (iFrameRef, queries, endpoint) => {
            let requestUrl = `${baseUrl}/${endpoint}?state=${encodeURIComponent(state)}&`;
            for(const query in queries){
                requestUrl += `${query}=${encodeURIComponent(queries[query])}&`
            }
            iFrameRef.src = requestUrl.slice(0,-1);
            //iFrameRef.src = baseUrl;
        }
    }
}

const axiosWrapper = {
    useAxiosState
};

export default axiosWrapper;
