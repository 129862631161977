import { createContext, useContext } from "solid-js";
import manager from "./manager";

const AuthorizationContext = createContext(manager);

const AuthorizationProvider = (props) => {
    return <AuthorizationContext.Provider manager={manager}>
        {props.children}
    </AuthorizationContext.Provider>
};

const useAuthorization = () => {
    return useContext(AuthorizationContext);
};

export {useAuthorization, AuthorizationProvider};