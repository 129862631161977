import { useSearchParams } from "@solidjs/router";
import { onMount } from "solid-js";
import { useAuthorization } from "./context/Auth/AuthorizationProvider";

const App = () => {
    const [queries, setQueries] = useSearchParams();
    const [{message}, {refresh, token}] = useAuthorization();

    let iFrame;

    window.addEventListener(
        "message",
        async (event)=>{
            if(event.origin!==import.meta.env.VITE_THIS_BASE_URL || typeof event.data != "string"){
                return //console.log("unacceptable event")
            }
            //console.log("running token req");
            await token(event.data);
        }
    )

    onMount(()=>{
        if(queries["path"]){
            sessionStorage.setItem("loc", queries["path"]);
        }
        refresh(iFrame);
    });

    return <>
        {message() ? <div>⚠ {message()} ⚠</div> : <></>}
        <iframe ref={iFrame} id="login-form" 
            title="login-form"
            style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"
            //sandbox="allow-same-origin allow-forms"
        >
            your browser does not support iframes.
        </iframe>
        {/* {initform} */}
    </>
};

export default App;
