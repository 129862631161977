import { createEffect, createSignal } from "solid-js";
import authService from "../../services/auth";


const [message, setMessage] = createSignal();

const token = async (code) => {

    if(typeof code != "string") return;
    
    
    try{
        await authService.createToken(code);
    } catch(err) {
        setMessage(err.message);
    }
}

const refresh = async (iFrameRef) => {
    try{
        await authService.refreshPage(iFrameRef);
    } catch(err) {
        setMessage(err.message);
    }
};

const manager = [
    {
        message
    },
    {
        refresh,
        token
    }
];

export default manager;